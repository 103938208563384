/* Scripts for simple horizontal slider. Use cycle for add Event Listener for all horizontal sliders on page */

var allHorizontalSliders = document.getElementsByClassName("horizontal-slider");

for (var numberOfSlider = 0; numberOfSlider < allHorizontalSliders.length; numberOfSlider++) {

    /* Set data-attr for slides */
    var horizontalSlides = allHorizontalSliders[numberOfSlider].getElementsByClassName('slide');
    for (var hs = 0; hs < horizontalSlides.length; hs++) {
        horizontalSlides[hs].setAttribute('data-horizontal-slider-slide', 'horizontal-slider__' + 'slide-' + hs);
    }

    /* Set data-attr for dot paginator controls */
    var horizontalContainerDots = allHorizontalSliders[numberOfSlider].querySelector('.container-dots');
    var horizontalDots = horizontalContainerDots.getElementsByTagName('span');
    for (var hd = 0; hd < horizontalDots.length; hd++) {
        horizontalDots[hd].setAttribute('data-horizontal-slider-dot', 'horizontal-slider__' + 'slide-' + hd);
        /* Add dots EventListener */
        var dot = horizontalDots[hd];
        dot.addEventListener('click', showHorizontalSlideByNumber);
    }

    /* Set data-attr for chevrons */
    var horizontalChevronLeft = allHorizontalSliders[numberOfSlider].querySelector('.chevron.left');
    var horizontalChevronRight = allHorizontalSliders[numberOfSlider].querySelector('.chevron.right');

    /* Add chevrons(arrows) EventListener */
    horizontalChevronLeft.addEventListener('click', showPrevHorizontalSlide);
    horizontalChevronRight.addEventListener('click', showNextHorizontalSlide);
}

function showPrevHorizontalSlide() {
    var activeSlide = this.closest('.horizontal-slider').querySelector('.slide.active');
    var activeDot = this.closest('.horizontal-slider').querySelector('.dot.active');
    var prevSlide = activeSlide.previousElementSibling;
    var prevDot = activeDot.previousElementSibling;
    if (activeSlide === this.closest('.horizontal-slider').querySelector(".container-slides").firstElementChild) {
        prevSlide = this.closest('.horizontal-slider').querySelector(".container-slides").lastElementChild;
        prevDot = this.closest('.horizontal-slider').querySelector(".container-dots").lastElementChild;
    }
    activeSlide.classList.remove('slide-in-left');
    activeSlide.classList.remove('slide-in-right');
    activeSlide.classList.remove('slide-out-left');
    activeSlide.classList.remove('slide-out-right');
    prevSlide.classList.remove('slide-in-left');
    prevSlide.classList.remove('slide-in-right');
    prevSlide.classList.remove('slide-out-left');
    prevSlide.classList.remove('slide-out-right');


    activeSlide.classList.add('slide-out-right');

    prevSlide.classList.add('active');
    prevSlide.classList.add('slide-in-left');
    activeDot.classList.remove('active');
    prevDot.classList.add('active');

    activeSlide.classList.remove('active');
}

function showNextHorizontalSlide() {
    var activeSlide = this.closest('.horizontal-slider').querySelector('.slide.active');
    var activeDot = this.closest('.horizontal-slider').querySelector('.dot.active');
    var nextSlide = activeSlide.nextElementSibling;
    var nextDot = activeDot.nextElementSibling;
    if (activeSlide === this.closest('.horizontal-slider').querySelector(".container-slides").lastElementChild) {
        nextSlide = this.closest('.horizontal-slider').querySelector(".container-slides").firstElementChild;
        nextDot = this.closest('.horizontal-slider').querySelector(".container-dots").firstElementChild;
    }
    activeSlide.classList.remove('slide-in-left');
    activeSlide.classList.remove('slide-in-right');
    activeSlide.classList.remove('slide-out-left');
    activeSlide.classList.remove('slide-out-right');
    nextSlide.classList.remove('slide-in-left');
    nextSlide.classList.remove('slide-in-right');
    nextSlide.classList.remove('slide-out-left');
    nextSlide.classList.remove('slide-out-right');

    activeSlide.classList.add('slide-out-left');

    nextSlide.classList.add('active');
    nextSlide.classList.add('slide-in-right');
    activeDot.classList.remove('active');
    nextDot.classList.add('active');

    activeSlide.classList.remove('active');
}

function showHorizontalSlideByNumber() {
    var activeSlide = this.closest('.horizontal-slider').querySelector('.slide.active');
    var allSlideInSlider = this.closest('.horizontal-slider').getElementsByClassName('slide');
    var activeDot = this.closest('.horizontal-slider').querySelector('.container-dots span.active');
    var selectedSlide = this.closest('.horizontal-slider').querySelector('[data-horizontal-slider-slide="' + this.getAttribute('data-horizontal-slider-dot') + '"]');
    var selectedDot = this.closest('.horizontal-slider').querySelector('[data-horizontal-slider-dot="' + this.getAttribute('data-horizontal-slider-dot') + '"]');


    /* Remove old sliding classes */
    for (var ois = 0; ois < allSlideInSlider.length; ois++) {
        allSlideInSlider[ois].classList.remove('slide-in-left');
        allSlideInSlider[ois].classList.remove('slide-in-right');
        allSlideInSlider[ois].classList.remove('slide-out-left');
        allSlideInSlider[ois].classList.remove('slide-out-right');
    }

    /* Check selected position for get left or right slide */
    if (activeSlide.getAttribute('data-horizontal-slider-slide').slice(-1) <= this.getAttribute('data-horizontal-slider-dot').slice(-1)) {
        activeSlide.classList.add('slide-out-left');
        selectedSlide.classList.add('slide-in-right');
    } else {
        activeSlide.classList.add('slide-out-right');
        selectedSlide.classList.add('slide-in-left');
    }


    activeSlide.classList.remove("active");
    selectedSlide.classList.add("active");
    activeDot.classList.remove("active");
    selectedDot.classList.add("active");
}